<template>
  <transition name="fade" appear>
    <div class="modal" v-show="show">
      <div
        class="modal__background"
        @click="$emit('hideModal')"
        :style="{ backgroundColor: bgColor }"
      ></div>
      <div class="modal__body" :style="{ backgroundColor: themeLight ? '#fff' : '#000' }">
        <IconButton
          v-if="menu"
          class="modal__menu menu__button button--lg button--outline-transparent"
          @click="setOpenMenu(true)"
        >
          <IconMenu></IconMenu>
        </IconButton>
        <IconButton
          class="modal__close button--outline-grey button--lg"
          :class="{
            modal__close_circle: closeCircle,
            modal__close_left: closeLeft,
            'button--outline-grey': themeLight,
            'button--outline-white': !themeLight,
          }"
          @click="$emit('hideModal')"
        >
          <IconClose v-if="!closeBold" width="20" height="20"></IconClose>
          <IconCloseBold v-if="closeBold" width="30" height="30"></IconCloseBold>
        </IconButton>
        <transition name="pop" appear>
          <div class="modal__body_content" v-show="show">
            <slot></slot>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconClose from "@/components/icons/IconClose.vue";
import IconCloseBold from "@/components/icons/IconCloseBold.vue";
import IconMenu from "@/components/icons/IconMenu.vue";
import { mapMutations } from "vuex";

export default {
  name: "modal",
  props: {
    themeLight: { type: Boolean, default: true },
    bgColor: { type: String, default: "#fff" },
    show: { type: Boolean, default: false },
    closeCircle: { type: Boolean, default: false },
    closeBold: { type: Boolean, default: false },
    closeLeft: { type: Boolean, default: false },
    menu: { type: Boolean, default: false },
  },
  components: { IconButton, IconClose, IconMenu, IconCloseBold },
  methods: {
    ...mapMutations(["setOpenMenu"]),
  },
};
</script>
<style lang="scss" scoped>
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 1;
  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    cursor: pointer;
  }
  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $white;
    padding: 5.5rem 0;
    &_content {
      position: relative;
      width: 105rem;
      height: 100%;
      //max-width: 90vw;
      z-index: 9;
      padding: 0 5vw;
      overflow-y: auto;
      //overflow: hidden;
      @media only screen and (max-width: $xxl) {
      }
      @media only screen and (max-width: $md) {
        width: 95rem;
        padding: 0 2vw;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        padding: 8rem 0 20px;
      }
    }
  }
  &__menu {
    position: absolute;
    right: 6rem;
    top: 4rem;
    height: 6.4rem;
    width: 6.4rem;
    background-color: transparent;
    z-index: 10;
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
  }
  &__close {
    position: absolute;
    right: 4rem;
    top: 4rem;
    z-index: 10;
    @media only screen and (max-width: $md) {
      right: 2rem;
      top: 2rem;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      right: 1rem;
      top: 1rem;
    }
    @media (max-width: $xxs) {
      width: 5.5rem;
    }
    &_circle {
      width: 7rem;
      border-radius: 50%;
      border: 2px solid $black;
      @media (max-width: $xxs) {
        width: 5.5rem;
      }
    }
    &_left {
      right: auto;
      left: 4rem;
      @media only screen and (max-width: $md) {
        left: 2rem;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        left: 1rem;
      }
    }
  }
  &--xs {
    .modal__body {
      max-width: 88rem;
      max-height: 95vh;
      height: auto;
      border-radius: 14px;
      &_content {
        padding: 1rem 15rem;
      }
    }
    .modal__close {
      right: 2rem;
      top: 2rem;
      width: 6rem;
      height: 6rem;
      padding: 0 12px;
    }
  }
  &--s {
    .modal__body {
      max-width: 105rem;
      max-height: 95vh;
      height: auto;
      border-radius: 14px;
      &_content {
        padding: 1rem 9rem;
      }
    }
    .modal__close {
      right: 2rem;
      top: 2rem;
      width: 6rem;
      height: 6rem;
      padding: 0 12px;
    }
  }
  &--m {
    .modal__body {
      max-width: 125rem;
      max-height: 100vh;
      height: auto;
      border-radius: 0;
      padding: 5.5rem 0;
      @media only screen and (max-width: $sm) {
        padding: 3rem 0;
      }
      &_content {
        width: 100%;
        padding: 0;
      }
    }
    .modal__close {
      right: 2rem;
      top: 0;
      width: 6.7rem;
      height: 6.7rem;
      padding: 0 12px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        width: 5rem;
        height: 5rem;
      }
    }
    .modal__menu {
      top: 0;
      right: 11rem;
      width: 6.7rem;
      height: 6.7rem;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        width: 5rem;
        height: 5rem;
        right: 9rem;
      }
    }
  }
}

/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-leave-to {
  transition-delay: 0.3s;
}

.pop-enter-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.3s linear;
}
.pop-leave-active {
  transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.2s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.5) translateY(-50%, -50%);
}
</style>
